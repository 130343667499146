import { Dimensions, Platform, StatusBar } from 'react-native'
import DeviceInfo from 'react-native-device-info'

const width = Math.round(Dimensions.get('window').width)
const height = Math.round(Dimensions.get('window').height)
const statusBarHeight = Platform.OS === 'android' ? StatusBar.currentHeight ?? 0 : 0

export const Layout = {
  window: {
    width,
    height,
    statusBarHeight,
  },
  isSmallDevice: Platform.select({ web: width <= 580, default: !DeviceInfo.isTablet() }),
}
